import { ChangeDetectionStrategy, Component, inject, Input, signal, WritableSignal } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { DocumentResolver } from '@services/resolvers';
import { HyphenatePipe, UrlFixedPipe, VirtualPathPipe } from '../../../pipes';

import { IS_SERVER } from '@core/config';
import { LinkObject } from '@models/interfaces';

@Component({
  standalone: true,
  selector: 'sps-sanitized-html',
  templateUrl: './sanitized-html.component.html',
  styleUrls: ['./sanitized-html.component.scss'],
  providers: [DocumentResolver],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SanitizedHtmlComponent {
  @Input() set html(html: string) {
    html = HyphenatePipe.transform(html || '');

    this.resolveDocumentLinks(html).then(enrichedHtml => {
      this.$sanitizedHtml.set(this.sanitizer.bypassSecurityTrustHtml(enrichedHtml));
    });
  }

  public $sanitizedHtml: WritableSignal<SafeHtml> = signal('');

  private documentResolver = inject(DocumentResolver);
  private sanitizer = inject(DomSanitizer);
  private isServer = inject(IS_SERVER);

  private async resolveDocumentLinks(html: string): Promise<string> {
    if (this.isServer) {
      return html;
    }

    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    const mediaLinks: HTMLAnchorElement[] = Array.from(doc.querySelectorAll('a[data-entity-uuid][data-entity-type="media"]'));

    if (mediaLinks.length) {
      await this.enrichLinks(mediaLinks);
    }

    return doc.documentElement.innerHTML;
  }

  private async enrichLinks(mediaLinks: HTMLAnchorElement[]): Promise<void> {
    const links: LinkObject[] = await Promise.all(
      mediaLinks.map(async mediaLink => {
        const uuid = mediaLink.getAttribute('data-entity-uuid');
        const href = await this.documentResolver.getFileUrlForDocument(uuid);

        if (href?.toLowerCase()?.endsWith('.pdf')) {
          const documentNode = await this.documentResolver.getDocumentNode(uuid);
          return DocumentResolver.buildDocumentTarget(documentNode?.data?.relationships?.field_media_document?.data?.id);
        }

        return { href: UrlFixedPipe.transform(href) };
      })
    );

    links.forEach((link, i) => {
      const mediaLink = mediaLinks[i];

      if (!link.href) {
        mediaLink.remove();
        return;
      }

      mediaLink.setAttribute('href', link.href);

      if (link.target) {
        mediaLink.setAttribute('target', link.target);
      }
    });
  }
}
